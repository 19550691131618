.footer_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    background: #fff;
    color:#a3a3a3;
    font-size: 12px;
    /* border-top: 1px solid #bbb; */
    color: #000;
}