@import url(https://fonts.googleapis.com/css?family=Exo+2:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Exo 2', sans-serif;
  min-height: 100vh;
  background: #fafafa;
  background: #fff;
}

.menu_wrapper {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  transition: .3s;
}

.menu_wrapper .logo {
  width: 20%;
}

.menu_wrapper .nav {
  width: 60%;
}

.menu_wrapper .contato {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon {
  height: 40px;
  width: 40px;
  background: #f50;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon i {
  color: #fff;
}

.menu_wrapper .contato .tel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: .3s;
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 400;
}

.menu_wrapper .contato .tel:hover {
  background: #fff;
  color: #f50;
}

.menu_wrapper .contato .scrollTel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: .3s;
  cursor: pointer;
  border: 1px solid #f50;
  color: #f50;
  font-weight: 400;
}

.menu_wrapper .contato .scrollTel:hover {
  background: #f50;
  color: #fff;
}

.back {
  background: #fff;
  box-shadow: 0px 5px 15px 0px #2223252d;
  transition: .3s;
  height: 80px;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: #fff;
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: #fff;
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: #fff;
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}
/*# sourceMappingURL=responsive.css.map */
.home_wrapper_misto {
  width: 100%;
}

.home_wrapper_misto .headline_wrapper {
  height: 300px;
  width: 100%;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_wrapper_misto .headline_wrapper .headline_group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.home_wrapper_misto .headline_wrapper .headline_group .headline_text {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: -1.4px;
  width: 100%;
  color: #222325;
  white-space: nowrap;
}

.home_wrapper_misto .headline_wrapper .headline_group .headline_text span {
  color: #f50;
  font-size: 2.5rem;
}

.home_wrapper_misto .headline_wrapper .headline_group p {
  color: #a3a3a3;
  font-weight: 600;
}

.home_wrapper_misto .video_wrapper {
  height: 300px;
}

.home_wrapper_misto .video_wrapper iframe {
  position: relative;
  top: -200px;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 100%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .video_wrapper {
    height: 300px;
  }
  .home_wrapper .video_wrapper iframe {
    width: 100%;
    position: relative;
    top: -250px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 100%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .video_wrapper {
    height: 300px;
  }
  .home_wrapper .video_wrapper iframe {
    width: 100%;
    position: relative;
    top: -250px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 100%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .video_wrapper {
    height: 300px;
  }
  .home_wrapper .video_wrapper iframe {
    width: 100%;
    position: relative;
    top: -250px;
  }
}
/*# sourceMappingURL=responsive.css.map */
.home_wrapper {
  height: 500px;
  width: 100%;
}

.home_wrapper .headline_wrapper {
  height: 650px;
  width: 100%;
  background: linear-gradient(to right, #ff550069, #ff5500b2), url(/static/media/man.a6befa07.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_wrapper .headline_wrapper .headline_group {
  position: relative;
  top: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_wrapper .headline_wrapper .headline_group .headline_text {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: 400;
  width: 100%;
  color: #fff;
}

.home_wrapper .headline_wrapper .headline_group .headline_text span {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
}

.home_wrapper .headline_wrapper .headline_group p {
  color: #fff;
  position: relative;
  top: -10px;
  font-size: 14px;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 80%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .slide_wrapper {
    width: 90%;
    top: -280px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 80%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .slide_wrapper {
    width: 100%;
    top: -280px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_wrapper .headline_wrapper {
    min-height: 650px;
    height: auto;
    width: 100%;
  }
  .home_wrapper .headline_wrapper .headline_group {
    position: relative;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 600;
    letter-spacing: -1.4px;
    width: 100%;
    color: #222325;
  }
  .home_wrapper .headline_wrapper .headline_group .headline_text span {
    color: #f50;
  }
  .home_wrapper .headline_wrapper .headline_group p {
    color: #a3a3a3;
    font-weight: 600;
    width: 90%;
  }
  .home_wrapper .slide_wrapper {
    width: 100%;
    top: -250px;
  }
}
/*# sourceMappingURL=responsive.css.map */
.mail_wrapper {
  min-height: 500px;
  padding: 50px 0px;
  height: auto;
  width: 100%;
  background: #fff;
  line-height: 1.5;
  color: #6f767d;
  letter-spacing: -0.7px;
}

.mail_wrapper .title-default {
  width: 80%;
  height: auto;
  padding: 20px 0px 20px 0px;
  margin: 0 auto;
}

.mail_wrapper .title-default h1 {
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 600;
  color: #222325;
}

.mail_wrapper .title-default strong {
  color: #f50;
}

.mail_wrapper .conteudo_box_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
  text-align: justify;
  width: 50%;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
  margin: 10px 0px;
  padding: 20px;
  text-align: justify;
  box-shadow: 0px 0px 15px 0px #2223252d;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
  width: 50%;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
  position: relative;
  left: -40px;
  text-align: left;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
  list-style: none;
  margin: 10px 0px;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
  content: url(/static/media/correct1.fd0a02b1.svg);
  margin-right: 10px;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
  width: 50%;
  text-align: justify;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
  width: 50%;
}

.mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
  width: 70%;
}

.mail_wrapper .button {
  width: 300px;
  height: 50px;
  color: #fff;
  background: #f50;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: .3s;
  cursor: pointer;
}

.mail_wrapper .button:hover {
  background: #a13600;
}

.reverse {
  flex-direction: row-reverse;
}

.subtitle {
  text-transform: uppercase;
  color: #222325;
}

.img_conteudo img {
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.164);
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .mail_wrapper .title-default {
    width: 85%;
    height: 80px;
    padding: 20px 0px;
    margin: 0 auto;
  }
  .mail_wrapper .title-default h1 {
    font-size: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .mail_wrapper {
    min-height: 500px;
    padding: 10px 0px;
    height: auto;
    width: 100%;
    background: url(/static/media/texture.597fe4ab.png), #F7F6F4;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .mail_wrapper .title-default {
    width: 90%;
    height: 80px;
    padding: 20px 0px;
    margin: 0 auto;
  }
  .mail_wrapper .title-default h1 {
    font-size: 2rem;
  }
  .mail_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 100%;
    text-align: justify;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 90%;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 100%;
    margin-top: 30px;
  }
  .reverse {
    flex-direction: column;
  }
  .subtitle {
    text-transform: uppercase;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mail_wrapper {
    min-height: 500px;
    padding: 10px 0px;
    height: auto;
    width: 100%;
    background: url(/static/media/texture.597fe4ab.png), #F7F6F4;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .mail_wrapper .title-default {
    width: 90%;
    height: 80px;
    padding: 0px 0px;
    margin: 0 auto;
  }
  .mail_wrapper .title-default h1 {
    font-size: 1.8rem;
  }
  .mail_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 100%;
    text-align: justify;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 90%;
  }
  .mail_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 100%;
    margin-top: 30px;
  }
  .reverse {
    flex-direction: column;
  }
  .subtitle {
    text-transform: uppercase;
  }
}
/*# sourceMappingURL=responsive.css.map */
.conteudo_wrapper {
  min-height: 500px;
  height: auto;
  padding-bottom: 50px;
  width: 100%;
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Exo 2', sans-serif;
  line-height: 1.5;
  color: #6f767d;
  letter-spacing: -0.7px;
}

.conteudo_wrapper .title-default {
  width: 60%;
  height: 80px;
  padding: 20px 0px 20px 0px;
  margin: 0 auto;
}

.conteudo_wrapper .title-default h1 {
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif;
  font-size: 2.7rem;
  font-weight: 300;
  color: #222325;
}

.conteudo_wrapper .headline_conteudo {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
}

.conteudo_wrapper .subtitle {
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.7rem;
  text-align: left;
  color: #222325;
}

.conteudo_wrapper .subtitle h2 {
  font-weight: 300;
}

.conteudo_wrapper .conteudo_box_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0px;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
  text-align: justify;
  width: 50%;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
  margin: 10px 0px;
  padding: 20px;
  text-align: justify;
  box-shadow: 0px 0px 15px 0px #2223252d;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
  width: 50%;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
  position: relative;
  left: -40px;
  text-align: left;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
  list-style: none;
  margin: 10px 0px;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
  content: url(/static/media/correct1.fd0a02b1.svg);
  margin-right: 10px;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
  width: 50%;
  text-align: justify;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
  width: 50%;
}

.conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
  width: 70%;
  box-shadow: 0px 15px 15px 0px #2223252d;
}

.conteudo_wrapper .link_area {
  height: 50px;
  color: #fff;
  background: #f50;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conteudo_wrapper .link_area a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
  transition: .3s;
}

.conteudo_wrapper .link_area a:hover {
  color: #742700;
}

.conteudo_wrapper .button {
  width: 500px;
  height: 50px;
  color: #fff;
  background: #f50;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: .3s;
  cursor: pointer;
}

.conteudo_wrapper .button:hover {
  background: #a13600;
}

.reverse {
  flex-direction: row-reverse;
}

.text_session {
  color: #f50;
  text-transform: uppercase;
  font-weight: bold;
}

.pagina_mail {
  width: 100%;
  height: 100vh;
  border: none;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .conteudo_wrapper {
    min-height: 500px;
    height: auto;
    padding-bottom: 50px;
    width: 100%;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Exo 2', sans-serif;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .conteudo_wrapper .title-default {
    width: 90%;
    height: auto;
    padding: 20px 0px x;
    margin: 0 auto;
  }
  .conteudo_wrapper .title-default h1 {
    font-size: 2.2rem;
  }
  .conteudo_wrapper .headline_conteudo {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .conteudo_wrapper .subtitle {
    text-transform: uppercase;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.7rem;
    text-align: left;
    color: #222325;
  }
  .conteudo_wrapper .subtitle h2 {
    font-weight: 300;
  }
  .conteudo_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo {
    width: 90%;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
    text-align: justify;
    width: 90%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
    margin: 10px 0px;
    padding: 20px;
    text-align: justify;
    box-shadow: 0px 0px 15px 0px #2223252d;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo img {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 80%;
    text-align: justify;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .im img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .link_area {
    height: 50px;
    color: #fff;
    background: #f50;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conteudo_wrapper .link_area a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5px;
    transition: .3s;
  }
  .conteudo_wrapper .link_area a:hover {
    color: #742700;
  }
  .conteudo_wrapper .button {
    width: 90%;
    height: 50px;
    color: #fff;
    background: #f50;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: .3s;
    cursor: pointer;
    padding: 10px 0px;
  }
  .conteudo_wrapper .button:hover {
    background: #a13600;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .conteudo_wrapper {
    min-height: 500px;
    height: auto;
    padding-bottom: 50px;
    width: 100%;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Exo 2', sans-serif;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .conteudo_wrapper .title-default {
    width: 90%;
    height: auto;
    padding: 20px 0px x;
    margin: 0 auto;
  }
  .conteudo_wrapper .title-default h1 {
    font-size: 2.2rem;
  }
  .conteudo_wrapper .headline_conteudo {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .conteudo_wrapper .subtitle {
    text-transform: uppercase;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.7rem;
    text-align: left;
    color: #222325;
  }
  .conteudo_wrapper .subtitle h2 {
    font-weight: 300;
  }
  .conteudo_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo {
    width: 90%;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
    text-align: justify;
    width: 90%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
    margin: 10px 0px;
    padding: 20px;
    text-align: justify;
    box-shadow: 0px 0px 15px 0px #2223252d;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo img {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 80%;
    text-align: justify;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .im img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .link_area {
    height: 50px;
    color: #fff;
    background: #f50;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conteudo_wrapper .link_area a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5px;
    transition: .3s;
  }
  .conteudo_wrapper .link_area a:hover {
    color: #742700;
  }
  .conteudo_wrapper .button {
    width: 90%;
    height: 50px;
    color: #fff;
    background: #f50;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: .3s;
    cursor: pointer;
    padding: 10px 0px;
  }
  .conteudo_wrapper .button:hover {
    background: #a13600;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .conteudo_wrapper {
    min-height: 500px;
    height: auto;
    padding-bottom: 50px;
    width: 100%;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Exo 2', sans-serif;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .conteudo_wrapper .title-default {
    width: 90%;
    height: auto;
    padding: 20px 0px x;
    margin: 0 auto;
  }
  .conteudo_wrapper .title-default h1 {
    font-size: 2.2rem;
  }
  .conteudo_wrapper .headline_conteudo {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .conteudo_wrapper .subtitle {
    text-transform: uppercase;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.7rem;
    text-align: left;
    color: #222325;
  }
  .conteudo_wrapper .subtitle h2 {
    font-weight: 300;
  }
  .conteudo_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo {
    width: 90%;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
    text-align: justify;
    width: 90%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
    margin: 10px 0px;
    padding: 20px;
    text-align: justify;
    box-shadow: 0px 0px 15px 0px #2223252d;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo img {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 80%;
    text-align: justify;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .im img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .link_area {
    height: 50px;
    color: #fff;
    background: #f50;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conteudo_wrapper .link_area a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5px;
    transition: .3s;
  }
  .conteudo_wrapper .link_area a:hover {
    color: #742700;
  }
  .conteudo_wrapper .button {
    width: 90%;
    height: 50px;
    color: #fff;
    background: #f50;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: .3s;
    cursor: pointer;
    padding: 10px 0px;
  }
  .conteudo_wrapper .button:hover {
    background: #a13600;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .conteudo_wrapper {
    min-height: 500px;
    height: auto;
    padding-bottom: 50px;
    width: 100%;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Exo 2', sans-serif;
    line-height: 1.5;
    color: #6f767d;
    letter-spacing: -0.7px;
  }
  .conteudo_wrapper .title-default {
    width: 90%;
    height: auto;
    padding: 20px 0px x;
    margin: 0 auto;
  }
  .conteudo_wrapper .title-default h1 {
    font-size: 2.2rem;
  }
  .conteudo_wrapper .headline_conteudo {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .conteudo_wrapper .subtitle {
    text-transform: uppercase;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.7rem;
    text-align: left;
    color: #222325;
  }
  .conteudo_wrapper .subtitle h2 {
    font-weight: 300;
  }
  .conteudo_wrapper .conteudo_box_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo {
    width: 90%;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .conteudo_text {
    text-align: justify;
    width: 90%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .sub_conteudo {
    margin: 10px 0px;
    padding: 20px;
    text-align: justify;
    box-shadow: 0px 0px 15px 0px #2223252d;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo .img_conteudo img {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul {
    position: relative;
    left: -40px;
    text-align: left;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li {
    list-style: none;
    margin: 10px 0px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b ul li::before {
    content: url(/static/media/correct1.fd0a02b1.svg);
    margin-right: 10px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .conteudo_text_b {
    width: 80%;
    text-align: justify;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .img_conteudo {
    width: 100%;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .imc img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .conteudo_box_wrapper .boxes_conteudo_b .im img {
    width: 85%;
    box-shadow: 0px 15px 15px 0px #2223252d;
    margin-top: 30px;
  }
  .conteudo_wrapper .link_area {
    height: 50px;
    color: #fff;
    background: #f50;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conteudo_wrapper .link_area a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5px;
    transition: .3s;
  }
  .conteudo_wrapper .link_area a:hover {
    color: #742700;
  }
  .conteudo_wrapper .button {
    width: 90%;
    height: 50px;
    color: #fff;
    background: #f50;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: .3s;
    cursor: pointer;
    padding: 10px 0px;
  }
  .conteudo_wrapper .button:hover {
    background: #a13600;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}
/*# sourceMappingURL=responsive.css.map */
.footer_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    background: #fff;
    color:#a3a3a3;
    font-size: 12px;
    /* border-top: 1px solid #bbb; */
    color: #000;
}
.slide_container {
  min-height: 600px;
  height: auto;
  width: 100%;
  background: #fff;
  padding: 50px 0px;
}

.slide_container .headline_group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.slide_container .headline_group .headline_text {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 600;
  letter-spacing: -1.4px;
  width: 70%;
  color: #222325;
}

.slide_container .headline_group .headline_text span {
  color: #f50;
}

.slide_container .headline_group p {
  color: #a3a3a3;
  font-weight: 600;
}

.slide_container .slide_wrapper {
  width: 70%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0px 15px 10px 0px rgba(0, 0, 0, 0.233);
}

.slide_container .slide_wrapper .carousel.carousel-slider .control-arrow {
  background: #000 !important;
  opacity: .3;
}
/*# sourceMappingURL=style.css.map */
